<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="手机号" >
                <el-input v-model="search.shouJiHao" size="small" />
            </x-search-item>
            <x-search-item label="卡号" >
                <el-input v-model="search.kaHao" size="small" />
            </x-search-item>
            <x-search-item label="姓名" >
                <el-input v-model="search.xingMing" size="small" />
            </x-search-item>
            <x-search-item label="充值时间" textWidth="230px" hasEnd>
                <el-date-picker v-model="search.chongZhiSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.chongZhiSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div><br>

        <!--        概览区-->

        <div class="">
            <el-row :gutter="12" style="text-align: center">
                <el-col :span="4" >
                  <el-card shadow="hover">
                    充值金额<br> {{total.shiFuJinE}}
                  </el-card>
                </el-col>
                <el-col :span="4">
                  <el-card shadow="hover">
                    赠送金额<br>{{total.zengSongJinE}}
                  </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card shadow="hover">
                        到账金额<br> {{total.daoZhangJinE}}
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card shadow="hover">
                        充值退款金额<br> {{total.tuiKuanJinE}}
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <x-divider title=""></x-divider>
      <!-- 批量按钮区 -->
        <div class="x-page-btn">
<!--            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>-->
<!--            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">-->
<!--                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>-->
<!--            </el-popconfirm>-->
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="40" fixed/>-->
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="shouJiHao" label="手机号" />
            <el-table-column width="180" prop="kaHao" label="卡号" />
            <el-table-column width="180" prop="xingMing" label="姓名" />
            <el-table-column width="180" prop="chongZhiMenDian" label="充值门店" />
            <el-table-column width="180" prop="chongZhiFS" label="充值方式" >
                <x-dict-show slot-scope="{row}" :code="row.chongZhiFS" dictType="T_CHONG_ZHI_FS" />
            </el-table-column>
            <el-table-column width="180" prop="shiFuJinE" label="实付金额" />
            <el-table-column width="180" prop="zengSongJinE" label="赠送金额" />
            <el-table-column width="180" prop="daoZhangJinE" label="到账金额" />
            <el-table-column width="180" prop="zengSongJiFen" label="赠送积分"/>
            <el-table-column width="180" prop="chongZhiSJ" label="充值时间" />
            <el-table-column width="180" prop="chongZhiChangJing" label="充值场景" >
                <x-dict-show slot-scope="{row}" :code="row.chongZhiChangJing" dictType="T_BianDong_CJ" />
            </el-table-column>
            <el-table-column width="180" prop="dingDanZhuangTai" label="订单状态" >
                <x-dict-show slot-scope="{row}" :code="row.dingDanZhuangTai" dictType="T_DING_DAN_ZT" />
            </el-table-column>
            <el-table-column width="180" prop="caoZuoRenYuan" label="操作人员" />
            <el-table-column width="180" prop="beiZhu" label="备注" />
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
<!--                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>-->
<!--                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>-->
                    <el-popconfirm title="确定要对该次充值进行退款操作吗吗？" @confirm="chongZhiTuiKuan(row.id)">
                        <el-button v-if="row.dingDanZhuangTai===dingDanZhuangTai" type="danger" size="mini" round slot="reference">退款</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/hygl/ChongZhiJiLu";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/hygl/czjl/ChongZhiJiLuEdit";
    import Detail from "@/view/hygl/czjl/ChongZhiJiLuDetail";
    import {DICT_TYPE_BDCJ, DICT_TYPE_CZZT} from "@/util/constant";

    export default {
        name: "ChongZhiJiLuList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                dingDanZhuangTai:DICT_TYPE_CZZT.YDZ.key,
                search: {
                    shouJiHao: '',
                    kaHao: '',
                    xingMing: '',
                    chongZhiSJBegin: '',
                    chongZhiSJEnd: '',
                },
                total:{
                    shiFuJinE:"",
                    zengSongJinE:"",
                    daoZhangJinE:"",
                    tuiKuanJinE:"",
                }
            }
        },
        mounted() {
            service.total().then(res=>{
                // console.log(res.data)
                this.total.shiFuJinE=res.data.shiFuJinE
                this.total.zengSongJinE=res.data.zengSongJinE
                this.total.daoZhangJinE=res.data.daoZhangJinE
                this.total.tuiKuanJinE=res.data.tuiKuanJinE
                // console.log(this.huiyuanTotal)
                // console.log(this.jifenTotal)
                // console.log(this.yueTotal)
            })
        },
        methods:{
            chongZhiTuiKuan(id){
                let data={
                    id:id,
                    caoZuoRen:this.$store.getters.user.name,
                    bianDongMenDian:"",
                    cj:DICT_TYPE_BDCJ.MDHT.key,
                };
                service.cztk(data).then(res=>{
                    console.log(res);
                    if (res.code===200){
                        this.$message.success("已退款");
                        this.refresh();
                    }else {
                        this.$message.warning("卡内余额不足")
                    }
                })
            }
        }
    }
</script>

<style scoped>
</style>
