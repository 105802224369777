<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="bianHao" label="编号" />
            <x-detail-item prop="shouJiHao" label="手机号" />
            <x-detail-item prop="kaHao" label="卡号" />
            <x-detail-item prop="xingMing" label="姓名" />
            <x-detail-item prop="chongZhiMenDian" label="充值门店" />
            <x-detail-item prop="chongZhiChangJing" label="充值场景" />
            <x-detail-item prop="chongZhiFS" label="充值方式" />
            <x-detail-item prop="shiFuJinE" label="实付金额" />
            <x-detail-item prop="zengSongJinE" label="赠送金额" />
            <x-detail-item prop="daoZhangJinE" label="到账金额" />
            <x-detail-item prop="chongZhiSJ" label="充值时间" />
            <x-detail-item prop="caoZuoRenYuan" label="操作人员" />
            <x-detail-item prop="beiZhu" label="备注" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hygl/ChongZhiJiLu";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                bianHao: "", // 编号
                shouJiHao: "", // 手机号
                kaHao: "", // 卡号
                xingMing: "", // 姓名
                chongZhiMenDian: "", // 充值门店
                chongZhiChangJing: "", // 充值场景
                chongZhiFS: "", // 充值方式
                shiFuJinE: "", // 实付金额
                zengSongJinE: "", // 赠送金额
                daoZhangJinE: "", // 到账金额
                chongZhiSJ: "", // 充值时间
                caoZuoRenYuan: "", // 操作人员
                beiZhu: "", // 备注
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>