<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="编号" prop="bianHao" >
                <el-input v-model="form.bianHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="手机号" prop="shouJiHao" >
                <el-input v-model="form.shouJiHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="卡号" prop="kaHao" >
                <el-input v-model="form.kaHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="姓名" prop="xingMing" >
                <el-input v-model="form.xingMing" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="充值门店" prop="chongZhiMenDian" >
                <el-input v-model="form.chongZhiMenDian" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="充值场景" prop="chongZhiChangJing" >
                <el-input v-model="form.chongZhiChangJing" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="充值方式" prop="chongZhiFS" >
                <el-input v-model="form.chongZhiFS" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="实付金额" prop="shiFuJinE" >
                <el-input v-model="form.shiFuJinE" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="赠送金额" prop="zengSongJinE" >
                <el-input v-model="form.zengSongJinE" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="到账金额" prop="daoZhangJinE" >
                <el-input v-model="form.daoZhangJinE" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="充值时间" prop="chongZhiSJ">
                <el-date-picker v-model="form.chongZhiSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="操作人员" prop="caoZuoRenYuan" >
                <el-input v-model="form.caoZuoRenYuan" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="备注" prop="beiZhu" >
                <el-input v-model="form.beiZhu" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hygl/ChongZhiJiLu";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                bianHao: [], // 编号
                shouJiHao: [], // 手机号
                kaHao: [], // 卡号
                xingMing: [], // 姓名
                chongZhiMenDian: [], // 充值门店
                chongZhiChangJing: [], // 充值场景
                chongZhiFS: [], // 充值方式
                shiFuJinE: [], // 实付金额
                zengSongJinE: [], // 赠送金额
                daoZhangJinE: [], // 到账金额
                chongZhiSJ: [], // 充值时间
                caoZuoRenYuan: [], // 操作人员
                beiZhu: [], // 备注
            }
            this.titlePrefix = '充值记录';
            this.defaultForm = {
                id: null,
                bianHao: "", // 编号
                shouJiHao: "", // 手机号
                kaHao: "", // 卡号
                xingMing: "", // 姓名
                chongZhiMenDian: "", // 充值门店
                chongZhiChangJing: "", // 充值场景
                chongZhiFS: "", // 充值方式
                shiFuJinE: "", // 实付金额
                zengSongJinE: "", // 赠送金额
                daoZhangJinE: "", // 到账金额
                chongZhiSJ: "", // 充值时间
                caoZuoRenYuan: "", // 操作人员
                beiZhu: "", // 备注
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>